import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from 'react-router-dom';

//* COMPONENT
import Header from 'src/Components/PDF/Header'
import Assinatura from 'src/Components/PDF/Assinatura'
import ResumoPedido from './Pedido'
import Produtos from './Produtos'
import Emprestimos from './Emprestimos'

//* CONTEXT
import usePedidos from "src/Contexts/PedidosContext";
import useLoading from "src/Contexts/LoadingContext";
import useTheme from 'src/Contexts/ThemeContext';

//* PROVIDER
import { PedidosProvider } from "src/Contexts/PedidosContext";
import { LoadingProvider } from "src/Contexts/LoadingContext";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});

// TESTE
export default function PedidoPDF() {
  return (
    <LoadingProvider>
      <PedidosProvider>
        <RenderPDF />
      </PedidosProvider>
    </LoadingProvider>
  )
}

// TESTE
function RenderPDF() {
  const { id } = useParams();
  const { getPedido, pedido } = usePedidos();
  const { loadingGet } = useLoading();
  const { imagens } = useTheme();

  React.useEffect(() => {
    let render = true;

    if (id) {
      (async () => {
        if (render) {
          await getPedido(id);
        }
      })();
    }

    return function cleanup() {
      render = false;
    };

    //eslint-disable-next-line
  }, []);


  return (
    <PDFViewer style={{ flex: 1, width: "100%", height: "1000px" }}>
      {!loadingGet && pedido && <BasicDocument id={id} pedido={pedido} logo={imagens.logoPdf} />}
    </PDFViewer>
  )

}


// Create Document Component
function BasicDocument({ id, pedido, cliente, logo }) {

  return (
    <Document title="Resumo de Pedido" language="pt-BR">
      <Page size="A4" style={styles.page}>
        {cliente && <Header cliente={cliente} logo={logo} />}
        <ResumoPedido pedido={pedido} />
        {pedido && pedido.produtos && pedido.recebimento && pedido.produtos.length > 0 && <Produtos produtos={pedido.produtos} recebimento={pedido.recebimento} />}
        {pedido && pedido.emprestimo && <Emprestimos emprestimo={pedido.emprestimo} />}
        <Assinatura />
      </Page>
    </Document>
  );
}