import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
} from '@mui/material/';
import {
  usePopupState,
  bindTrigger,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { Icon } from '@iconify/react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

//* CONTEXT
import useMenu from '../Contexts/MenuContext';
import useUser from '../Contexts/UserContext';

const MyListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiListItemIcon-root': {
    minWidth: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const MyListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiTypography-root': {
    fontSize: 12,
    display: open ? 'block' : 'none',
  },
}));

const MyCollapse = styled(Collapse, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  '.MuiCollapse-root': {
    backgroundColor: '#212121',
  },
}));

export const ListMenu = ({ open }) => {
  const { Menus, selected, handleToggleSubMenu } = useMenu();
  const { role_id } = useUser();

  return (
    <React.Fragment>
      {Menus.map((menu) => {
        return (
          <React.Fragment key={menu.primary}>
            {menu.subMenu.length > 0
              ? open
                ? // Com SUBMENU e OPEN
                menu.access.includes(role_id) && (
                  <MenuWithSubMenuOpen
                    menu={menu}
                    open={open}
                    handleToggleSubMenu={handleToggleSubMenu}
                    selected={selected}
                  />
                )
                : menu.access.includes(role_id) && (
                  <MenuWithSubMenu
                    menu={menu}
                    open={open}
                    selected={selected}
                  />
                )
              : // Menu Comum
              menu.access.includes(role_id) && (
                <MyMenu
                  menu={menu}
                  open={open}
                  selected={menu.url === selected}
                />
              )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const MenuWithSubMenuOpen = ({ menu, handleToggleSubMenu, open, selected }) => {
  return (
    <React.Fragment>
      <MyListItemButton open={open} onClick={handleToggleSubMenu(menu.primary)}>
        {menu.Icon ? (
          <ListItemIcon>
            <menu.Icon />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <Icon icon={menu.iconIfy} width="25" height="25" />
          </ListItemIcon>
        )}

        <MyListItemText primary={menu.primary} open={open} />
        {menu.isOpen ? <ExpandLess /> : <ExpandMore />}
      </MyListItemButton>
      {menu.divider && <Divider />}
      {menu.subMenu.length > 0 &&
        menu.subMenu.map((submenu) => {
          return (
            <React.Fragment key={submenu.primary}>
              <SubMenuOpen
                submenu={submenu}
                open={menu.isOpen}
                selected={selected}
              />
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

const SubMenuOpen = ({ submenu, open, selected }) => {
  return (
    <MyCollapse
      in={open}
      timeout="auto"
      unmountOnExit
      sx={{ backgroundColor: '#212121' }}
    >
      <MyListItemButton
        href={submenu.url}
        open={open}
        selected={submenu.url === selected}
      >
        {submenu.Icon ? (
          <ListItemIcon>
            <submenu.Icon />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <Icon icon={submenu.iconIfy} width="25" height="25" />
          </ListItemIcon>
        )}
        <MyListItemText primary={submenu.primary} open={open} />
      </MyListItemButton>
    </MyCollapse>
  );
};

const MenuWithSubMenu = ({ menu, selected, open }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  return (
    <React.Fragment>
      <>
        <MyListItemButton
          {...bindHover(popupState)}
          {...bindTrigger(popupState)}
        >
          {menu.Icon ? (
            <ListItemIcon>
              <menu.Icon />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <Icon icon={menu.iconIfy} width="25" height="25" />
            </ListItemIcon>
          )}
        </MyListItemButton>
        {menu.divider && <Divider />}
      </>
      <HoverPopover
        sx={{ left: '56px' }}
        elevation={1}
        id="basic-menu"
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {menu.subMenu.length > 0 &&
          menu.subMenu.map((submenu, index) => {
            return (
              <MyListItemButton
                key={index}
                href={submenu.url}
                open={open}
                selected={submenu.url === selected}
              >
                {submenu.Icon ? (
                  <ListItemIcon>
                    <submenu.Icon />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <Icon icon={submenu.iconIfy} width="25" height="25" />
                  </ListItemIcon>
                )}
                <MyListItemText primary={submenu.primary} open={true} />
              </MyListItemButton>
            );
          })}
      </HoverPopover>
    </React.Fragment>
  );
};

// Normal Menu
const MyMenu = ({ menu, open, selected }) => {
  return (
    <React.Fragment>
      <Tooltip title={menu.primary} placement="right" arrow>
        <MyListItemButton href={menu.url} open={open} selected={selected}>
          {menu.Icon ? (
            <ListItemIcon>
              <menu.Icon />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <Icon icon={menu.iconIfy} width="25" height="25" />
            </ListItemIcon>
          )}

          <MyListItemText primary={menu.primary} open={open} />
        </MyListItemButton>
      </Tooltip>
      {menu.divider && <Divider />}
    </React.Fragment>
  );
};
