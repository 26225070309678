import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import { DateFormat } from "../../../../Utils/dates"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: 'row',
    width: "100%"
  },
  column50: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "flex-start",
    padding: 10,
    width: "50%",
  },
  column100: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "flex-start",
    padding: 10,
    width: "100%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'row',
    minHeight: "20px",
    padding: 10,
  },
  row2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'row',
    minHeight: "20px",
    width: "100%",
    padding: 10,
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 5,
  },
  subtitulo: {
    display: "flex",
    flexDirection: 'row',
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 5,
  },
  divider: {
    width: '4%',
  },
  description: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    width: '48%',
    borderTopColor: "black",
    textAlign: "center",
    borderTopWidth: 1,
    marginTop: 20,
  },
});

export default function Recibo({ recibo }) {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.titulo}>Recibo de Pagamento</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.column50}>
          <Text style={styles.subtitulo}>Pedido: # {recibo.id}</Text>
        </View>
        <View style={styles.column50}>
          <Text style={styles.subtitulo}>Recibo de: R$ {recibo.valor_recibo}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text>Recebi(emos) de {recibo.cliente.toUpperCase()}, CNPJ/CPF: {recibo.cnpj_cpf}, a importância de {recibo.valor_recibo} reais, pagamento efetuado via {recibo.forma_pg.toUpperCase()}, referente a {recibo.referente.toUpperCase()}.</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text>E para maior clareza firmo o presente recibo para que produza os seus efeitos, dando plena, rasa e irrevogável quitação, pelo valor recebido.</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row2}>
          <Text>{recibo.cidade}, {DateFormat(recibo.data_recebido,"DD/MM/YYYY")}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row2}>
          <Text style={styles.description}>Assinatura Empresa</Text>
        </View>
      </View>
    </>
  )
}  
