import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';


import PedidoPDF from '../Pages/Pedidos/PDF/Pedido';
import EmprestimoPDF from '../Pages/Emprestimos/PDF/Render';


//* CONTEXT
import useAuth from '../Contexts/AuthContext';

const LazyPerfil = React.lazy(() => import('../Pages/Perfil'));

// PEDIDOS
const LazyPedidos = React.lazy(() => import('../Pages/Pedidos'));
const LazyPedidosNew = React.lazy(() => import('../Pages/Pedidos/New'));
const LazyPedidosEdit = React.lazy(() => import('../Pages/Pedidos/Edit'));
const LazyPedidosView = React.lazy(() => import('../Pages/Pedidos/View'));
const LazyPedidosAgenda = React.lazy(() => import('../Pages/Pedidos/Agenda'));

// EMPRESTIMOS
const LazyEmprestimos = React.lazy(() => import('../Pages/Emprestimos'));
const LazyEmprestimosNew = React.lazy(() => import('../Pages/Emprestimos/New'));
const LazyEmprestimosEdit = React.lazy(() =>
  import('../Pages/Emprestimos/Edit')
);
const LazyEmprestimosView = React.lazy(() =>
  import('../Pages/Emprestimos/View')
);



export default function RotasVendedor({ role_id }) {

  return (
    <Routes>
      <Route element={<RequireAuthVendedor role_id={role_id} />}>

        {/** PEDIDOS */}
        <Route path="pedidos">
          <Route index element={<LazyPedidos />} />
          <Route path="new" element={<LazyPedidosNew />} />
          <Route path="edit/:id" element={<LazyPedidosEdit />} />
          <Route path="view/:id" element={<LazyPedidosView />} />
          <Route path="pdf/:id" element={<PedidoPDF />} />
          <Route path="agenda" element={<LazyPedidosAgenda />} />
        </Route>

        {/* EMPRESTIMOS */}
        <Route path="emprestimos">
          <Route index element={<LazyEmprestimos />} />
          <Route path="new" element={<LazyEmprestimosNew />} />
          <Route path="edit/:id" element={<LazyEmprestimosEdit />} />
          <Route path="view/:id" element={<LazyEmprestimosView />} />
          <Route path="pdf/:id" element={<EmprestimoPDF />} />
        </Route>

        <Route path="/perfil" element={<LazyPerfil />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}




function RequireAuthVendedor({ role_id }) {
  let location = useLocation();

  return role_id === 4 ? (
    <Outlet />
  ) : <Navigate to="/" state={{ from: location }} replace={true} />
}
