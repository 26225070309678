import React from 'react';

import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';



const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: 'row',
    borderBottom: "1px solid black",
  },
  detailColumn: {
    display: "flex",
    flexDirection: 'column',
    textTransform: 'uppercase',
    marginBottom: 5
  },
  image: {
    width: "75px",
    height: "55px",
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    lineHeight: 1.5,
    fontWeight: "bold"
  },
  subtitle: {
    fontSize: 10,
    lineHeight: 1.2,
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
  },
});




export default function Header({ cliente, logo }) {

  return (
    <View style={styles.container}>
      <View style={styles.detailColumn}>
        <Image
          src={logo}
          style={styles.image}
        />
      </View>
      <View style={styles.detailColumn}>
        <Text style={styles.title}>{cliente.pdf_nome_empresa && cliente.nome_fantasia ? cliente.nome_fantasia : cliente.razao_social}</Text>
        <Text style={styles.subtitle}>{cliente.logradouro},{cliente.numero} - {cliente.bairro}</Text>
        <Text style={styles.subtitle}>{cliente.cidade} - {cliente.estado}{cliente.pdf_usar_cep && cliente.cep && ` - CEP: ${cliente.cep}`}</Text>
        <Text style={styles.subtitle}>{cliente.cnpj}{cliente.pdf_usar_telefone && cliente.telefone && `- Telefone: ${cliente.telefone}`}{cliente.pdf_usar_whatsapp && cliente.whatsapp && ` - WhatsApp: ${cliente.whatsapp}`}</Text>
      </View>
    </View>
  )
}  
