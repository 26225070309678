import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import TableHeaderProduto from 'src/Components/PDF/TableHeaderProdutos'
import TableRowProdutos from 'src/Components/PDF/TableRowProdutos'
import TableFooterProdutos from 'src/Components/PDF/TableFooterProdutos'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  titulo: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    textAlign: "center",
    marginTop: 5,
  },
});

export default function Produtos({ produtos, recebimento }) {


  return (
    <>
      <Text style={styles.titulo}>Produtos</Text>
      <View style={styles.tableContainer}>
        <TableHeaderProduto />
        <TableRowProdutos items={produtos} />
        <TableFooterProdutos items={produtos} recebimento={recebimento} />
      </View>
    </>

  )
}  
