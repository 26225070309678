import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const mymoment = moment;

export function DateNow(): string {
  return moment().format();
}

export function DateFormat(date: string, format = 'YYYY-MM-DD'): string {
  return moment(date).format(format);
}

export function DateFormatInput(date: string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function DateTimeFormatInput(date: string): string {
  return moment(date).format('YYYY-MM-DDTHH:mm');
}

export function AddDaysDate(date: string, days: number): string {
  return moment(date).add(days, 'days').format('YYYY-MM-DD');
}

/**
 * Verifica se a data é anterior a hoje, se for retorna true;
 */
export function CompareDateBeforeToday(date: string): boolean {
  if (date) {
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now, 'days');
  }

  return true;
}

/**
 * Verifica se o pagamento da entrada é hj ou antes de hj;
 */
export function dateCheckPagamentoEntrada(date: string): boolean {
  if (date) {
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now, 'days') ||
      currDate.format('DD/MM/YYYY') === now.format('DD/MM/YYYY')
      ? true
      : false;
  }

  return true;
}

/**
 * Verifica se o do vencimento do boleto é hj é hj ou antes de hj;
 */
export function dateCheckBoletoVencimento(date: string): boolean {
  if (date) {
    const now = moment();
    const currDate = moment(date);
    return currDate.isBefore(now, 'days') ||
      currDate.format('DD/MM/YYYY') === now.format('DD/MM/YYYY')
      ? true
      : false;
  }

  return true;
}

export function nameMonth(mes: string): string {
  const name = moment(mes).locale('pt-br').format('MMMM');
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function diffStartAndEndDateMonths(start: Moment, end: Moment) {
  const diff = end.diff(start, 'months');

  let months = [];
  for (let index = 0; index <= diff; index++) {
    const c = moment(start).add(index, 'month');
    months.push({
      mes: parseInt(moment(c).format('M')),
      ano: parseInt(moment(c).format('YYYY')),
    });
  }

  return months;
}

/**
 * Verifica se de criacao da despesas é maior q 30 dias.
 * true faz desabilitar o botao de editar
 */
export function dateCheckDespesaEdit(date: string): boolean {
  if (date) {
    const passado = moment(new Date()).subtract(30, 'day');
    const currDate = moment(date);
    const diff = currDate.diff(passado, 'day');

    return diff <= 0 ? true : false;
  }

  return true;
}
