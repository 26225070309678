import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Toolbar, Typography } from "@mui/material";

import AddButton, { AddDialog } from '../Button/Add'

// import Search from "../Search";
// import useTable from "../../Contexts/TableContext";

const MyToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));


const MyTypography = styled(Typography)(({ theme }) => ({
  flex: "1 1 100%",
}));


const TableToolbar = React.memo(({ title, addButton, loading, children, dialogAdd }) => {
  // const { handleChangeSearch } = useTable();

  return (
    <MyToolbar>
      <React.Fragment>
        <MyTypography
          variant="h6"
          id="tableTitle"
          component="span"
        >
          {title}
        </MyTypography>
      </React.Fragment>
      {/* <Search handleChangeSearch={handleChangeSearch} /> */}
      {!loading && children}
      {addButton && <AddButton name="Cadastrar" path={addButton} />}
      {dialogAdd && <AddDialog name="Cadastrar" action={dialogAdd} />}
    </MyToolbar>
  );
});

export default TableToolbar;

TableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.bool.isRequired
};

