import React, { createContext, useState, useContext } from 'react';
import { loadCSS } from 'fg-loadcss';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';
import { CssBaseline } from '@mui/material/';

import Clientes from "../Store/Clientes";
const roboto = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Roboto'),
    local('Roboto-Medium'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


const ThemeContext = createContext({});

const Cliente = Clientes.find(cliente => window.location.origin.indexOf(cliente.domain) >= 0);

const MyThemeProvider = ({ children }) => {
  const [darkMode, setdarkMode] = useState(true);
  const palette = React.useMemo(() => Cliente.palette, []);
  const imagens = React.useMemo(() => Cliente.imagem, []);

  React.useEffect(() => {
    const node = loadCSS(
      'https://pro.fontawesome.com/releases/v5.9.0/css/all.css',
      document.querySelector('#font-awesome-css') || document.head.firstChild
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  let MyTheme = createTheme({
    darkMode: darkMode,
    primary: palette.primary,
    backgroundColor: palette.backgroundColor,
    backgroundColorLogin: palette.backgroundColorLogin,
    backgroundColorPaper: palette.backgroundColorPaper,
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.secondary,
      },
      terceira: {
        main: palette.terceira,
      },
      entradas: '#2980b9',
      pedidos: '#d35400',
      estoquePositivo: '#27ae60',
      estoqueNegativo: '#e74c3c',
      status: {
        entregaAgendada: '#3498db',
        entregaAgendadaBackground: (opacity = 1) =>
          `rgba(41, 128, 185, ${opacity})`, //#2980b9
        entregaPedente: '#e67e22',
        entregaPedenteBackground: (opacity = 1) =>
          `rgba(211, 84, 0, ${opacity})`, //#d35400
        entregue: '#95a5a6',
        entregueBackground: (opacity = 1) => `rgba(41, 128, 185, ${opacity})`, //#2980b9
        devolucaoPendente: '#A55712',
        devolucaoPendenteBackground: (opacity = 1) =>
          `rgba(165,87,18, ${opacity})`, //#d35400
        pagamentoPedente: '#f1c40f',
        pagamentoPedenteBackground: (opacity = 1) =>
          `rgba(243, 156, 18, ${opacity})`, //#f39c12
        pago: '#1abc9c',
        pagoBackground: (opacity = 1) => `rgba(22, 160, 133, ${opacity})`, //#16a085
        finalizado: '#27ae60',
        finalizadoBackground: (opacity = 1) => `rgba(46, 204, 113, ${opacity})`, //#2ecc71
        cancelado: '#e74c3c',
        canceladoBackground: (opacity = 1) => `rgba(192, 57, 43, ${opacity})`, //#c0392b
      },
      background: {
        default: darkMode ? '#333' : '#ddd',
        paper: darkMode ? '#424242' : 'white',
      },
      text: {
        title: darkMode ? 'white' : palette.primary,
        common: darkMode ? '#ddd' : '#404040',
      },
      button: {
        common: darkMode ? '#000' : '#ffffff',
        hover: {},
      },
      border: {
        common: darkMode ? 'rgba(255, 255, 255, 0.12)' : '#ddd',
      },
    },
    menuWidth: 240,
    size: {
      button: {
        common: 18,
      },
    },
    // Font Awasem
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
          '#root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          },
          '@font-face': [roboto],
          a: {
            textDecoration: 'none',
            color: 'whitesmoke',
          },
          '*::-webkit-scrollbar': {
            width: '1em',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            // borderBottomLeftRadius: 100,
            // borderBottomRightRadius: 100,
            backgroundColor: palette.primary,
            backgroundClip: 'content-box',
          },
          '*::-webkit-outer-spin-button, *::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '*[type=number]': {
            '-moz-appearance': 'textfield',
          },
        }),
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            boxSizing: 'content-box',
            padding: 3,
            fontSize: '1.125rem',
          },
        },
      },
    },
  });

  MyTheme = responsiveFontSizes(MyTheme);

  return (
    <ThemeProvider theme={MyTheme}>
      <CssBaseline />
      <ThemeContext.Provider
        value={{
          darkMode,
          setdarkMode,
          imagens
        }}
      >
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};


export default function useTheme() {
  return useContext(ThemeContext);
}

export { ThemeContext, MyThemeProvider };
