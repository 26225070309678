import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { List, Typography, Box } from '@mui/material';
import { ListMenu } from './ListMenu';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  [theme.breakpoints.down('md')]: {
    position: "absolute",
    height: "100%"
  },
  '& .MuiToolbar-root': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiDrawer-paper': {
    position: 'relative',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingTop: '64px',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

export default function DrawerContent({ open }) {
  // const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <List component="nav">
          <ListMenu open={open} />
        </List>
        {!open ? (
          <Box display="flex" justifyContent="center" flexDirection="column" sx={{
            alignItems: "center",
            position: "absolute",
            bottom: 15,
            color: "#808080",
            width: 70
          }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: 11 }} >Beerify</Typography>
            <Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: 10 }} >v.{process.env.REACT_APP_VERSION}</Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" flexDirection="column" sx={{
            alignItems: "center",
            position: "absolute",
            bottom: 15,
            color: "#808080",
            width: 238
          }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: 11 }} >Beerify - Versão {process.env.REACT_APP_VERSION}</Typography>
          </Box>
        )}

      </Drawer>
    </>
  );
}
