const Clientes = [
  {
    domain: 'offbeerbq.com.br',
    palette: {
      primary: '#ef4923',
      secondary: '#bad531',
      terceira: '#bad531',
      backgroundColor: '#201f1e',
      backgroundColorPaper: '#201f1e',
      backgroundColorLogin: '#201f1e',
    },
    imagem: {
      logoLogin: '/static/images/logos/offbeer/logo.png',
      fundoLogin: '/static/images/logos/offbeer/fundo_login.png',
      iconAvatar: '/static/images/logos/offbeer/avatar.png',
      logoAppBar: '/static/images/logos/offbeer/logoAppBar.png',
      logoPdf: '/static/images/logos/offbeer/logo.png',
    },
  },
  {
    domain: 'offbeerbq.com.br',
    palette: {
      primary: '#ef6a00',
      secondary: '#121e28',
      terceira: '#44489d',
      backgroundColor: '#201f1e',
      backgroundColorPaper: '#201f1e',
      backgroundColorLogin: '#44489d',
    },
    imagem: {
      logoLogin: '/static/images/logos/capital/logo.png',
      fundoLogin: '/static/images/logos/capital/fundo_login.png',
      iconAvatar: '/static/images/logos/capital/avatar.png',
      logoAppBar: '/static/images/logos/capital/logoAppBar.png',
      logoPdf: '/static/images/logos/capital/logopdf.png',
    },
  },
  {
    domain: 'localhost',
    palette: {
      primary: '#ef6a00',
      secondary: '#121e28',
      terceira: '#44489d',
      backgroundColor: '#201f1e',
      backgroundColorPaper: '#201f1e',
      backgroundColorLogin: '#44489d',
    },
    imagem: {
      logoLogin: '/static/images/logos/capital/logo.png',
      fundoLogin: '/static/images/logos/capital/fundo_login.png',
      iconAvatar: '/static/images/logos/capital/avatar.png',
      logoAppBar: '/static/images/logos/capital/logoAppBar.png',
      logoPdf: '/static/images/logos/capital/logopdf.png',
    },
  },
];

export default Clientes;
