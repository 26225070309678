import { IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';
import { Service } from './Services';

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError';
// Validate
import {
  newPedidoSchema,
  editPedidoSchema,
  editPedidoSchemaWithRetirada,
  editPedidoStatusSchema,
} from '../Utils/validations/Pedidos.schema';

export interface IPedido {
  id?: number;
  cod?: number;
  status: string;
  cliente_id: string;
  entrega: any;
  obs?: string;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
}

export type INewPedido = {
  entrega: any;
  recebimento: any;
  produtos: any;
  cliente: any;
  devolucao: any;
} & IPedido;

interface IFiltro {
  tipo?: string;
  status?: string;
  cliente_id?: number;
  data_inicial?: string;
  data_final?: string;
}

export class PedidosService extends Service<IPedido> {
  constructor(path: string) {
    super(path);
    this.path = path;
  }

  public async getPedidos(): Promise<
    AxiosResponse<IResponseSuccess<IPedido[]>>
  > {
    try {
      const response = await this.getAll();
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getPedidosByClienteID(
    cliente_id: number
  ): Promise<AxiosResponse<IResponseSuccess<IPedido[]>>> {
    try {
      const response = await this.index(`/pedidos/cliente/${cliente_id}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getPedido(
    id: number
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      const response = await this.getOne(id);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getPedidosByStatus(
    status: string
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      const response = await this.get(`/pedidos/find/status?status=${status}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getPedidosByFiltros(
    filtros: IFiltro
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      let query = filtros.status
        ? `filtros?status=${filtros.status}`
        : 'filtros?';
      query = filtros.tipo ? query + `&tipo=${filtros.tipo}` : query;
      query = filtros.cliente_id
        ? query + `&cliente_id=${filtros.cliente_id}`
        : query;
      query =
        filtros.data_inicial && filtros.data_final
          ? query +
            `&data_inicial=${filtros.data_inicial}&data_final=${filtros.data_final}`
          : query;

      const response = await this.get(`/pedidos/find/${query}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getStatusCount(): Promise<
    AxiosResponse<IResponseSuccess<IPedido | number>>
  > {
    try {
      const response = await this.get(`/pedidos/status/count`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async getStatusValores(
    filtros: IFiltro
  ): Promise<AxiosResponse<IResponseSuccess<IPedido | number>>> {
    try {
      let query = filtros && filtros.status ? `${filtros.status}?` : 'all?';
      query +=
        filtros && filtros.cliente_id ? `cliente_id=${filtros.cliente_id}` : '';
      query +=
        filtros && filtros.data_inicial && filtros.data_final
          ? `&data_inicial=${filtros.data_inicial}&data_final=${filtros.data_final}`
          : '';
      const response = await this.get(`/pedidos/status/valor/${query}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async editPedido(
    Dados: IPedido
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      const data =
        Dados.entrega.tipo === '1'
          ? await editPedidoSchema<IPedido>(Dados)
          : await editPedidoSchemaWithRetirada<IPedido>(Dados);

      const response = await this.edit(data);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async editPedidoStatus(
    Dados: IPedido
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      const data = await editPedidoStatusSchema<IPedido>(Dados);

      const response = await this.put(`/pedidos/status/${data.id}`, data);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async newPedido(
    Dados: INewPedido,
    Entrega: any
  ): Promise<AxiosResponse<IResponseSuccess<IPedido | INewPedido>>> {
    try {
      const Pedido = await newPedidoSchema<INewPedido>(Dados);
      const response = await this.new({ ...Pedido, entrega: Entrega });
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  public async nextStatusPedido(
    id: number
  ): Promise<AxiosResponse<IResponseSuccess<IPedido>>> {
    try {
      const response = await this.put(`/pedidos/next/status/${id}`);
      return response;
    } catch (error) {
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }
}

export const pedidosServices = new PedidosService('/pedidos');
