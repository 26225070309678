import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


import { convertReal } from '../../Utils/currency'
const borderColor = '#ccc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableFooter = ({ items, recebimento }) => {
    const total = items.map(item => item.qnt * item.valor)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)



    return (
        <>
            {
                recebimento && recebimento.juros && (
                    <View style={styles.row}>
                        <Text style={styles.description}>Juros</Text>
                        <Text style={styles.total}>+ {convertReal(recebimento.juros)}</Text>
                    </View>
                )
            }

            {
               recebimento &&  recebimento.desconto && (
                    <View style={styles.row}>
                        <Text style={styles.description}>Descontos</Text>
                        <Text style={styles.total}>- {convertReal(recebimento.desconto)}</Text>
                    </View>

                )
            }

            {
               recebimento &&  recebimento.sinal && (
                    <View style={styles.row}>
                        <Text style={styles.description}>Sinal Pago</Text>
                        <Text style={styles.total}>- {convertReal(recebimento.sinal)}</Text>
                    </View>

                )
            }

            { recebimento &&  recebimento.pago === 1 ? (
                <View style={styles.row}>
                    <Text style={styles.description}>TOTAL PAGO</Text>
                    <Text style={styles.total}>{convertReal(recebimento.valor)}</Text>
                </View>
            ) : (

                <View style={styles.row}>
                    <Text style={styles.description}>TOTAL À PAGAR</Text>
                    <Text style={styles.total}>{convertReal(((total + recebimento.juros) - recebimento.desconto) - recebimento.sinal)}</Text>
                </View >
            )}

        </>
    )
};

export default InvoiceTableFooter