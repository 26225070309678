import { Api, IResponseSuccess } from './Api.service';
import { AxiosResponse } from 'axios';

// API Error;
import { ApiResponseError } from '../Classes/ApiResponseError';

export class Service<T> extends Api<T> {
  protected path: string;

  constructor(path: string) {
    super();
    this.path = path;
  }

  protected async getAll(): Promise<AxiosResponse<IResponseSuccess<T[]>>> {
    try {
      const response = await this.index(this.path);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  protected async getOne(
    id: number
  ): Promise<AxiosResponse<IResponseSuccess<T>>> {
    try {
      const response = await this.get(`${this.path}/${id}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  protected async new(Dados: T): Promise<AxiosResponse<IResponseSuccess<T>>> {
    try {
      const response = await this.post(this.path, Dados);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  protected async edit(
    Dados: T & { id?: number }
  ): Promise<AxiosResponse<IResponseSuccess<T>>> {
    try {
      if (Dados.id) {
        const response = await this.put(`${this.path}/${Dados.id}`, Dados);
        return response;
      }

      throw new Error('ID não foi enviado.');
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }

  protected async del(
    filename: string
  ): Promise<AxiosResponse<IResponseSuccess<T>>> {
    try {
      const response = await this.deletar(`${this.path}/${filename}`);
      return response;
    } catch (error) {
      console.log(error);
      if (error instanceof ApiResponseError) {
        throw error.message;
      }
      throw error;
    }
  }
}
