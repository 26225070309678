

export function convertReal(valor: number){

  if(typeof valor !== "number") return "R$ 0.0"

  const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 }
  const real = new Intl.NumberFormat('pt-BR', options);
  return real.format(valor);
}




export function calcValorProdutos(valor: number, desconto: number, juros: number){
  const v = valor && typeof valor === "number" ? valor : 0
  const d = desconto && typeof desconto === "number" ? desconto : 0
  const j = juros && typeof juros === "number" ? juros : 0

  return convertReal((v-j)+d)

}