import * as yup from "yup";
import Validate from "./Validate";

export async function newPedidoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		obs: yup.string().notRequired().nullable(),
    cliente: yup.object().shape({
      id: yup.number().required(),
    }),
    recebimento: yup.object().shape({
			forma_pg: yup.string().trim().required(), 
			cod_boleto: yup.string().trim().notRequired().nullable(),
			ven_boleto: yup.date().notRequired().nullable(),
			pago: yup.number().notRequired().default(0),
			cartao_bandeira: yup.string().trim().notRequired().nullable(), 
			valor: yup.string().trim().required(),
			desconto: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			juros: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			sinal: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
		}),
    produtos: yup.array(),
		devolucao: yup.object().shape({
			tipo: yup.number().required(),
			data: yup.date().notRequired(),
		}),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}



export async function editPedidoSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		id: yup.number().required(),
		status: yup.string().required(),
		obs: yup.string().notRequired().nullable(),
    cliente: yup.object().shape({
      id: yup.number().required(),
    }),
    recebimento: yup.object().shape({
			id: yup.number().required(),
			forma_pg: yup.string().trim().required(), 
			cod_boleto: yup.string().trim().notRequired().nullable(),
			ven_boleto: yup.date().notRequired().nullable(),
			pago: yup.number().notRequired().default(0),
			data_pagamento: yup.date().notRequired().nullable(),
			cartao_bandeira: yup.string().trim().notRequired().nullable(), 
			valor: yup.string().trim().required(),
			desconto: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			juros: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			sinal: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
		}),
    produtos: yup.array(),
		entrega: yup.object().shape({
			id: yup.number().required(),
			tipo: yup.number().required(),
			data: yup.date().notRequired(),
			cep: yup
				.string()
				.trim()
				.min(5, "No mínimo 5 caracteres")
				.max(15, "No máximo 15 caracteres")
				.required(),
			estado: yup
				.string()
				.trim()
				.min(2, "No mínimo 2 caracteres")
				.max(2, "No máximo 2 caracteres")
				.notRequired(),
			cidade: yup
				.string()
				.trim()
				.min(5, "No mínimo 5 Caracteres")
				.max(100, "No máximo 100 Caracteres")
				.notRequired(),
			bairro: yup
				.string()
				.trim()
				.min(5, "No mínimo 5 Caracteres")
				.max(100, "No máximo 100 Caracteres")
				.notRequired(),
			logradouro: yup
				.string()
				.trim()
				.min(5, "No mínimo 5 Caracteres")
				.max(150, "No máximo 150 Caracteres")
				.notRequired(),
			numero: yup
				.number()
				.notRequired()
				.min(0, "Número não pode ser menor que zero.")
				.max(99999, "Número muito grande."),
			complemento: yup.string().trim().notRequired().nullable(),
		}),
		devolucao: yup.object().shape({
			id: yup.number().required(),
			tipo: yup.number().required(),
			data: yup.date().notRequired(),
		}),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function editPedidoSchemaWithRetirada<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		id: yup.number().required(),
		status: yup.string().required(),
		obs: yup.string().notRequired().nullable(),
    cliente: yup.object().shape({
      id: yup.number().required(),
    }),
    recebimento: yup.object().shape({
			id: yup.number().required(),
			forma_pg: yup.string().trim().required(), 
			cod_boleto: yup.string().trim().notRequired().nullable(),
			ven_boleto: yup.date().notRequired().nullable(),
			pago: yup.number().notRequired().default(0),
			cartao_bandeira: yup.string().trim().notRequired().nullable(), 
			valor: yup.string().trim().required(),
			desconto: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			juros: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
			sinal: yup.number().transform((value) => (isNaN(value) ? 0 : value)).notRequired().nullable().default(0),
		}),
    produtos: yup.array(),
		entrega: yup.object().shape({
			id: yup.number().required(),
			tipo: yup.number().required(),
			data: yup.date().notRequired(),
		}),
		devolucao: yup.object().shape({
			id: yup.number().required(),
			tipo: yup.number().required(),
			data: yup.date().notRequired(),
		}),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}

export async function editPedidoStatusSchema<T>(data: T): Promise<T> {
	const schema = yup.object().shape({
		id: yup.number().required(),
		status: yup.string().required(),
	});
	const validate = new Validate(schema, data);
	return await validate.exec();
}


