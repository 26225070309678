import * as yup from 'yup';
import Validate from './Validate';

export async function editClientePJSchema<T>(data: T): Promise<T> {
  const schema = yup.object().shape({
    id: yup.number().required(),
    nome_fantasia: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Nome Fantasia é obrigatório'),
    razao_social: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Razão Social é obrigatório'),
    cnpj_cpf: yup
      .string()
      .trim()
      .min(3, 'No mínimo 3 Caracteres')
      .max(30, 'No máximo 30 caracteres')
      .required('CNPJ é obrigatório'),
    ins_estadual: yup
      .string()
      .trim()
      .max(50, 'No máximo 50 caracteres')
      .optional()
      .nullable(),
    ins_municipal: yup
      .string()
      .trim()
      .max(50, 'No máximo 50 caracteres')
      .optional()
      .nullable(),
    revendedor: yup.number().default(0),
    tipo: yup.string().default('matriz'),
    cep: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 caracteres')
      .max(15, 'No máximo 15 caracteres')
      .required(),
    estado: yup
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .max(2, 'No máximo 2 caracteres')
      .required(),
    cidade: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Cidade é obrigatório'),
    bairro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Bairro é obrigatório'),
    logradouro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Logradouro é obrigatório'),
    numero: yup
      .number()
      // .transform((value) => (isNaN(value) ? 0 : value))
      .typeError('Valor não pode ser vazio.')
      .required('Numero é obrigatório')
      .min(0, 'Número não pode ser menor que zero.')
      .max(99999, 'Número muito grande.'),
    complemento: yup.string().trim().notRequired().nullable(),
    whatsapp: yup.string().notRequired().nullable(),
    telefone: yup.string().notRequired().nullable(),
    email: yup.string().email().notRequired().nullable(),
    obs: yup.string().trim().notRequired().nullable(),
    active: yup
      .number()
      .default(1)
      .transform((value, originalValue) => {
        return originalValue ? 1 : 0;
      }),
  });
  const validate = new Validate(schema, data);
  return await validate.exec();
}

export async function editClientePFSchema<T>(data: T): Promise<T> {
  const schema = yup.object().shape({
    id: yup.number().required(),
    nome_completo: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Nome Completo é obrigatório'),
    cnpj_cpf: yup
      .string()
      .trim()
      .min(3, 'No mínimo 3 Caracteres')
      .max(30, 'No máximo 30 caracteres')
      .required('Usuário é obrigatório'),
    revendedor: yup.number().default(0),
    tipo: yup.string().default('matriz'),
    estado: yup
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .max(2, 'No máximo 2 caracteres')
      .required(),
    cidade: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Cidade é obrigatório'),
    bairro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Bairro é obrigatório'),
    logradouro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Logradouro é obrigatório'),
    numero: yup
      .number()
      // .transform((value) => (isNaN(value) ? 0 : value))
      .required('Numero é obrigatório')
      .min(0, 'Número não pode ser menor que zero.')
      .max(99999, 'Número muito grande.'),
    complemento: yup.string().trim().notRequired().nullable(),
    whatsapp: yup.string().notRequired().nullable(),
    telefone: yup.string().notRequired().nullable(),
    email: yup.string().email().notRequired().nullable(),
    obs: yup.string().trim().notRequired().nullable(),
    active: yup
      .number()
      .default(1)
      .transform((value, originalValue) => {
        return originalValue ? 1 : 0;
      }),
  });
  const validate = new Validate(schema, data);
  return await validate.exec();
}

export async function newClientePJSchema<T>(data: T): Promise<T> {
  const schema = yup.object().shape({
    nome_fantasia: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Nome Fantasia é obrigatório'),
    razao_social: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Razão Social é obrigatório'),
    cnpj_cpf: yup
      .string()
      .trim()
      .min(3, 'No mínimo 3 Caracteres')
      .max(30, 'No máximo 30 caracteres')
      .required('CNPJ é obrigatório'),
    ins_estadual: yup
      .string()
      .trim()
      .max(50, 'No máximo 50 caracteres')
      .optional()
      .nullable(),
    ins_municipal: yup
      .string()
      .trim()
      .max(50, 'No máximo 50 caracteres')
      .optional()
      .nullable(),
    revendedor: yup.number().default(0),
    tipo: yup.string().default('matriz'),
    cep: yup
      .string()
      .trim()
      .min(9, 'No mínimo 9 caracteres.')
      .max(9, 'No máximo 9 caracteres.')
      .required(),
    estado: yup
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .max(2, 'No máximo 2 caracteres')
      .required(),
    cidade: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Cidade é obrigatório'),
    bairro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Bairro é obrigatório'),
    logradouro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Logradouro é obrigatório'),
    numero: yup
      .number()
      // .transform((value) => (isNaN(value) ? 0 : value))
      .typeError('Valor não pode ser vazio.')
      .required('Numero é obrigatório')
      .min(0, 'Número não pode ser menor que zero.')
      .max(99999, 'Número muito grande.'),
    complemento: yup.string().trim().notRequired().nullable(),
    whatsapp: yup.string().notRequired().nullable(),
    telefone: yup.string().notRequired().nullable(),
    email: yup.string().email().notRequired().nullable(),
    obs: yup.string().trim().notRequired().nullable(),
    active: yup.number().default(1),
  });
  const validate = new Validate(schema, data);
  return await validate.exec();
}

export async function newClientePFSchema<T>(data: T): Promise<T> {
  const schema = yup.object().shape({
    nome_completo: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Nome Completo é obrigatório'),
    cnpj_cpf: yup
      .string()
      .trim()
      .min(3, 'No mínimo 3 Caracteres')
      .max(30, 'No máximo 30 caracteres')
      .required('Usuário é obrigatório'),
    revendedor: yup.number().default(0),
    tipo: yup.string().default('matriz'),
    cep: yup
      .string()
      .trim()
      .min(9, 'No mínimo 9 caracteres.')
      .max(9, 'No máximo 9 caracteres.'),
    estado: yup
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .max(2, 'No máximo 2 caracteres')
      .required(),
    cidade: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Cidade é obrigatório'),
    bairro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(100, 'No máximo 100 Caracteres')
      .required('Bairro é obrigatório'),
    logradouro: yup
      .string()
      .trim()
      .min(5, 'No mínimo 5 Caracteres')
      .max(150, 'No máximo 150 Caracteres')
      .required('Logradouro é obrigatório'),
    numero: yup
      .number()
      // .transform((value) => (isNaN(value) ? 0 : value))
      .required('Numero é obrigatório')
      .min(0, 'Número não pode ser menor que zero.')
      .max(99999, 'Número muito grande.'),
    complemento: yup.string().trim().notRequired().nullable(),
    whatsapp: yup.string().notRequired().nullable(),
    telefone: yup.string().notRequired().nullable(),
    email: yup.string().email().notRequired().nullable(),
    obs: yup.string().trim().notRequired().nullable(),
    active: yup.number().default(1),
  });
  const validate = new Validate(schema, data);
  return await validate.exec();
}
