import * as React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, IconButton, useMediaQuery, Typography, Stack, Button, Link } from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

//* COMPONENTS
import DrawerContent from "./DrawerMenu";
import MenuUser from "./MenuUser";
import { EntradasButton, DespesasButton } from '../Components/Button/Add'

//* CONTEXT
import useMenu from '../Contexts/MenuContext'
import useUser from "../Contexts/UserContext";
import useTheme from "../Contexts/ThemeContext";

//* HOOKS

const MyAppBar = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	backgroundColor: theme.palette.primary.main,
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: theme.menuWidth,
		backgroundColor: theme.palette.primary.main,
		// width: `calc(100% - ${theme.menuWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function MenuAppBar() {
	const { role_id } = useUser();
	const { open, toggleDrawerMenu } = useMenu();
	const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const { imagens } = useTheme();

	return (
		<React.Fragment>
			<MyAppBar position="absolute" open={open} elevation={0}>
				<Toolbar>
					<img src={imagens.logoAppBar} alt="" width="74px" />
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={toggleDrawerMenu}
						sx={{ ml: 10 }}
					>
						{open ? <ChevronLeftIcon /> : <MenuIcon />}
					</IconButton>
					{
						!mdDown && !smDown && (
							<>
								{role_id <= 4 && <EntradasButton name="Pedido" path="pedidos" sx={{ marginLeft: 2 }} />}
								{role_id === 1 && <EntradasButton name="Entrada" path="entradas/financeiras" sx={{ marginLeft: 2 }} />}
								{role_id === 1 && <DespesasButton name="Compra" path="entradas/produtos" sx={{ marginLeft: 2 }} />}
								{role_id === 1 && <DespesasButton name="Despesa" path="despesas" sx={{ marginLeft: 2 }} />}
								{process.env.NODE_ENV === "development" && <Typography component="h1" sx={{ width: 430, color: "blue", fontSize: "1.5rem", ml: 2 }}>AMBIENTE DE DEV</Typography>}
							</>
						)
					}
					<Stack direction="row" spacing={2} sx={{ marginLeft: 2 }} >
						<Button variant="contained" color="secondary" component={Link} startIcon={<CalendarMonthIcon />} href={`/pedidos/agenda`} sx={{ marginLeft: 0, marginRight: 0, p: 1, "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 } }} />
					</Stack>
					<MenuUser />
				</Toolbar>
			</MyAppBar>
			<DrawerContent toggleDrawerMenu={toggleDrawerMenu} open={open} />
		</React.Fragment>
	);
}
