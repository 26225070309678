import * as React from 'react';
import { Stack, Button, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

export default function AddButton({ name, path, sx, Icon = AddIcon }) {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" color="secondary" component={Link} startIcon={<AddIcon />} href={`/${path}/new`} sx={{ ...sx }}>
        {name}
      </Button>
    </Stack>
  );
}


export function AddDialog({ name, action, sx, Icon = AddIcon }) {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => action()} sx={{ ...sx }}>
        {name}
      </Button>
    </Stack>
  );
}



export function EntradasButton({ name, path, sx, Icon = AddIcon }) {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" color="secondary" component={Link} startIcon={<AttachMoneyIcon />} href={`/${path}/new`} sx={{ ...sx }}>
        {name}
      </Button>
    </Stack>
  );
}

export function DespesasButton({ name, path, sx, Icon = AddIcon }) {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" color="secondary" component={Link} startIcon={<MoneyOffIcon />} href={`/${path}/new`} sx={{ ...sx }}>
        {name}
      </Button>
    </Stack>
  );
}