import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';

import PedidoPDF from '../Pages/Pedidos/PDF/Pedido';
import EmprestimoPDF from '../Pages/Emprestimos/PDF/Render';


const LazyPerfil = React.lazy(() => import('../Pages/Perfil'));

// PEDIDOS
const LazyPedidos = React.lazy(() => import('../Pages/Pedidos'));
const LazyPedidosView = React.lazy(() => import('../Pages/Pedidos/View'));
const LazyPedidosAgenda = React.lazy(() => import('../Pages/Pedidos/Agenda'));

// EMPRESTIMOS
const LazyEmprestimos = React.lazy(() => import('../Pages/Emprestimos'));
const LazyEmprestimosView = React.lazy(() =>
  import('../Pages/Emprestimos/View')
);


export default function RotasEntregador({ role_id }) {
  return (
    <Routes>
      <Route element={<RequireAuthEntregador role_id={role_id} />}>

        {/** PEDIDOS */}
        <Route path="pedidos">
          <Route path="view/:id" element={<LazyPedidosView />} />
          <Route path="pdf/:id" element={<PedidoPDF />} />
          <Route path="agenda" element={<LazyPedidosAgenda />} />
        </Route>

        {/* EMPRESTIMOS */}
        <Route path="emprestimos">
          <Route index element={<LazyEmprestimos />} />
          <Route path="view/:id" element={<LazyEmprestimosView />} />
          <Route path="pdf/:id" element={<EmprestimoPDF />} />
        </Route>

        <Route path="/perfil" element={<LazyPerfil />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>


    </Routes>
  );
}


function RequireAuthEntregador({ role_id }) {
  let location = useLocation();

  return role_id === 5 ? (
    <Outlet />
  ) : <Navigate to="/" state={{ from: location }} replace={true} />
}