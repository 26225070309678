import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  PDFViewer,
  BlobProvider,
} from "@react-pdf/renderer";
import { useParams } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';

//* COMPONENT
import { Button } from '@mui/material'
import Header from 'src/Components/PDF/Header'
import ReciboDados from './RecebidoDados';

import useTheme from 'src/Contexts/ThemeContext';

//* PROVIDER
import { PedidosProvider } from "src/Contexts/PedidosContext";
import { LoadingProvider } from "src/Contexts/LoadingContext";

//* HOOKS
import useLocalStore from 'src/Hooks/useLocalStore';
import { convertReal } from 'src/Utils/currency'

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});

// TESTE
export default function PedidoPDF() {
  return (
    <LoadingProvider>
      <PedidosProvider>
        <RenderPDF />
      </PedidosProvider>
    </LoadingProvider>
  )
}

// TESTE
function RenderPDF() {
  const { id } = useParams();
  const { getData } = useLocalStore();
  const cliente = getData("cliente");
  const recibo = {
    valor: convertReal(1060.80),
    cliente: "Diemerson Leandro das Neves",
    cpf_cnpj: "103.785.736-40",
    id,
    referente: "Evento de confraternizacao dos veteremos do 9 BPMMG",
    data_recibo: "24 de julho 2023",
    forma_pg: "Recibo",
    cidade: cliente.cidade
  }


  return (
    <PDFViewer style={{ flex: 1, width: "100%", height: "1000px" }}>
      <BasicDocumentRecibo cliente={cliente} recibo={recibo} />
    </PDFViewer>
  )

}


export function ReciboViewPDF({ cliente, recibo }) {

  const { imagens } = useTheme();

  return (
    <BlobProvider document={<BasicDocumentRecibo cliente={cliente} recibo={recibo} logo={imagens.logoPdf} />} >
      {({ url, blob }) => {
        return (
          <Button href={url} target="_blank" variant="contained" color="secondary" size="small" startIcon={<ReceiptIcon />} >Gerar Recibo</Button>
        );
      }}
    </BlobProvider>
  );

}


// Create Document Component
function BasicDocumentRecibo({ cliente, recibo, logo }) {

  return (
    <Document title="Recibo de Pagamento" language="pt-BR">
      <Page size="A4" style={styles.page}>
        {cliente && <Header cliente={cliente} logo={logo} />}
        {recibo && <ReciboDados recibo={recibo} />}
      </Page>
    </Document>
  );
}
