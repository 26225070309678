import React from "react";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

//** PROVIDER
import { LoginProvider } from "../../Contexts/LoginContext";

//* Component
import FormLogin from "./FormLogin";
import useTheme from "src/Contexts/ThemeContext";


const GridMain = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.backgroundColorLogin,
}));


const GridImagem = styled(Grid)(({ theme }) => ({
	height: '100vh',
	backgroundColor: theme.palette.primary.main,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	[theme.breakpoints.down('md')]: {
		display: "none"
	},
}));

const GridFromLogin = styled(Grid)(({ theme }) => ({
	padding: '50px',
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	[theme.breakpoints.down('md')]: {
		padding: '20px',
	},
}));


export default function Login() {
	const { imagens } = useTheme()

	return (
		<LoginProvider>
			<GridMain container position="fixed" display="flex" justifyContent="center" alignItems="center">
				<GridFromLogin
					item
					lg={4}
					sm={12}
					sx={{
						padding: '50px',
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<FormLogin />
				</GridFromLogin>
				<GridImagem
					item
					xs={8}
				>
					<img src={imagens.fundoLogin} loading="lazy" alt="fundo" />
				</GridImagem>
			</GridMain>
		</LoginProvider>
	);
}
